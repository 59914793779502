<template>
  <div class="container">
    <div class="card pb-0">
      <el-form :inline="true" class="screen-form">
        <el-form-item label="患者" class="pb-10">
          <el-input
            clearable
            v-model="form.patient"
            placeholder="支持姓名或编号"
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="移植类型" class="pb-10">
          <el-select
            clearable
            v-model="form.transplantType"
            fit-input-width
            placeholder="查看全部"
            style="width: 120px"
          >
            <el-option
              v-for="item in transplant"
              :key="'transplant_' + item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="筛选日期" class="pb-10">
          <el-select
            clearable
            v-model="form.dateType"
            fit-input-width
            placeholder="不筛选"
            style="width: 140px"
          >
            <el-option label="不筛选" :value="0"></el-option>
            <el-option label="手术日期" :value="1"></el-option>
            <el-option label="出院日期" :value="2"></el-option>
            <el-option label="上次随访日期" :value="3" disabled></el-option>
            <el-option label="下次随访日期" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期范围" class="pb-10">
          <el-date-picker
            clearable
            v-model="form.date"
            type="daterange"
            unlink-panels
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 250px"
            :disabled="form.dateType == 0"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="pb-10">
          <el-button type="primary" @click="getNumber">
            <el-icon><search /></el-icon>
            <span>筛选</span>
          </el-button>
          <el-button @click="clean">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="card table-card no-select" v-loading="loading">
      <template v-if="!error">
        <el-table
          :data="list"
          style="width: 100%; border-radius: 8px"
          :row-style="{ cursor: 'pointer' }"
          @row-click="openPatient"
          v-if="list.length > 0"
        >
          <el-table-column prop="id" label="编号" width="115" fixed />
          <el-table-column prop="name" label="姓名" min-width="90" fixed />
          <el-table-column label="性别" min-width="70">
            <template #default="scope">
              <div class="gender" :class="{ male: scope.row.gender === 1 }">
                <el-icon>
                  <male v-if="scope.row.gender === 1" />
                  <female v-else />
                </el-icon>
                <span>{{ scope.row.gender === 1 ? "男" : "女" }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="年龄" min-width="80">
            <template #default="scope"
              >{{ getAge(scope.row.birthday) }}岁</template
            >
          </el-table-column>
          <el-table-column
            prop="surgeryDate"
            label="手术日期"
            min-width="110"
          />
          <el-table-column
            prop="dischargeDate"
            label="出院日期"
            min-width="110"
          />
          <el-table-column
            prop="transplantTypeName"
            label="移植类型"
            min-width="120"
          />
          <el-table-column
            prop="lastDate"
            align="center"
            label="上次随访日期"
            width="120"
          />
          <el-table-column
            prop="nextDate"
            align="center"
            label="下次随访日期"
            width="120"
          />
            <el-table-column
              prop="progress"
              align="center"
              label="随访进度"
              min-width="140"
              class-name="progress"
            >
              <template #default="scope">
                <template v-if="scope.row.progress">
                  <div class="plan-number">
                    计划 {{ scope.row.progress.total }} 次
                  </div>
                  <el-progress
                    text-inside
                    :show-text="false"
                    :percentage="
                      ((scope.row.progress.total - scope.row.progress.waiting) /
                        scope.row.progress.total) *
                      100
                    "
                    status="success"
                  />
                  <div class="state-box">
                    <span class="state-complete"
                      >完成 {{ scope.row.progress.complete }}</span
                    >
                    ·
                    <span class="state-overdue"
                      >过期 {{ scope.row.progress.overdue }}</span
                    >
                  </div>
                </template>
              </template>
            </el-table-column>
          <el-table-column
            label="状态"
            align="right"
            width="80"
            fixed="right"
          >
            <template #default="scope">
              <el-tag type="info" effect="dark" v-if="scope.row.state === 1"
                >待审核</el-tag
              >
              <el-tag
                type="success"
                effect="dark"
                v-else-if="scope.row.state === 2"
                >已通过</el-tag
              >
              <el-tag
                type="warning"
                effect="dark"
                v-else-if="scope.row.state === 3"
                >已拒绝</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
        <el-empty class="loading" v-else description="暂无符合的随访患者">
          <el-button @click="clean">重置筛选条件</el-button>
        </el-empty>
      </template>
      <el-result
        v-else
        icon="error"
        title="加载失败"
        style="padding-top: calc(50vh - 220px)"
      />
    </div>
    <div class="card mb-0" v-if="!loading && total > 0">
      <div class="page-number">每页{{ number }}条 / 共{{ total }}条</div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="number"
        @current-change="getList"
      />
    </div>
  </div>
</template>

<script>
import { Search, Male, Female } from "@element-plus/icons";
import { common, patient } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "PatientList",
  components: { Search, Male, Female },
  data: () => ({
    list: [],
    form: {
      patient: "",
      transplantType: "",
      dateType: 0,
      date: "",
    },
    error: false,
    loading: true,
    transplant: [],
    number: 13,
    total: 0,
    page: 0,
  }),
  methods: {
    init() {
      let height = document.body.clientHeight;
      if (height > 768) this.number = 14 + parseInt((height - 768) / 40);
      this.getNumber();
      this.initCache();
    },
    initCache() {
      let cache = localStorage.getItem("cache_transplant");
      if (cache) this.transplant = JSON.parse(cache);
      common.initPage("/template/list").then((res) => {
        if (res.state) {
          localStorage.setItem("cache_transplant", JSON.stringify(res.data));
          this.transplant = res.data;
        }
      });
    },
    getNumber() {
      this.loading = true;
      patient
        .getNumber(this.form)
        .then((res) => {
          this.total = res.data;
          if (this.total > 0) this.getList(1);
          else {
            this.list = [];
            this.loading = false;
          }
        })
        .catch(() => {
          this.total = 0;
          this.error = true;
          this.loading = false;
        });
    },
    getList(page) {
      this.page = page;
      patient
        .getList(this.form, page, this.number)
        .then((res) => {
          for (let i in res.data) {
            this.buildDate(res.data[i], "dischargeDate", "yyyy-mm-dd");
            this.buildDate(res.data[i], "surgeryDate", "yyyy-mm-dd");
            if (res.data[i].lastDate)
              this.buildDate(res.data[i], "lastDate", "yyyy-mm-dd");
            else res.data[i].lastDate = "-";
            if (res.data[i].nextDate)
              this.buildDate(res.data[i], "nextDate", "yyyy-mm-dd");
            else res.data[i].nextDate = "-";
          }
          this.list = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    buildDate(obj, name, code) {
      obj[name] = date.format(obj[name], code);
    },
    clean() {
      this.form = {
        patient: "",
        transplantType: "",
        dateType: 0,
        date: "",
      };
      this.getNumber();
    },
    getAge(number) {
      let now = new Date();
      let birthdays = new Date(parseInt(number) * 1000);
      return (
        now.getFullYear() -
        birthdays.getFullYear() -
        (now.getMonth() < birthdays.getMonth() ||
        (now.getMonth() == birthdays.getMonth() &&
          now.getDate() < birthdays.getDate())
          ? 1
          : 0)
      );
    },
    openPatient(row) {
      this.$router.push("/patient/" + row.id);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.screen-form .el-form-item {
  margin: 0 10px 0 0;
}

.table-card {
  min-height: calc(100vh - 200px);
}

.table-card .gender {
  align-items: center;
  color: #b62424;
  display: flex;
}

.table-card .gender.male {
  color: #2b54a1;
}

.table-card .gender i {
  margin-right: 3px;
}

.loading {
  margin-top: calc(50vh - 300px);
}

.page-number {
  align-items: center;
  font-size: 14px;
  display: flex;
  color: #999;
  height: 35px;
  float: right;
}

.progress .plan-number,.progress .state-box {
  line-height: 14px;
  font-size: 12px;
}

.progress .plan-number {
  padding: 1px 0;
  color: #999; 
}

.progress .state-complete {
  color: #329700;
}

.progress .state-overdue {
  color: #c98317;
}
</style>